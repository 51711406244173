import React from "react";
import {CloseOutlined, WhatsAppOutlined} from '@ant-design/icons';
import {
    Button,
    Card, Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Image,
    Input,
    Radio,
    Row,
    Select,
    TimePicker,
    Typography
} from 'antd';
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const {Option} = Select;
const {TextArea} = Input;
const {Title, Text} = Typography;

const occasionList = [
    "Wedding",
    "Engagement",
    "Save the Date",
];

const eventList = [
    "Wedding",
    "Engagement",
    "Haldi",
    "Mehendi",
    "Sangeet",
    "Reception",
    "Cocktail",
    "Tilak/Sagan",
    "Roka",
    "Lagan",
    "Mayra",
    "Jagran",
    "Path",
    "Jaggo Night",
    "Other"
];

const maleNameSelectBefore = (
    <Select defaultValue="Mr.">
        <Option value="Mr. ">Mr.</Option>
        <Option value="Late Mr. ">Late Mr.</Option>
        <Option value="Sh. ">Sh.</Option>
        <Option value="Late Sh. ">Late Sh.</Option>
    </Select>
);

const femaleNameSelectBefore = (
    <Select defaultValue="Mrs.">
        <Option value="Mrs. ">Mrs.</Option>
        <Option value="Late Mrs. ">Late Mrs.</Option>
        <Option value="Smt. ">Smt.</Option>
        <Option value="Late Smt. ">Late Smt.</Option>
    </Select>
);

interface NameFieldProps {
    name: string;
    label: string;
    placeholder: string;
    prefix?: any;
    rules?: any;
}

const NameField = (props: NameFieldProps) => {
    const {name, label, placeholder, prefix, ...rest} = props;
    return <Form.Item name={[name, "name"]} label={label} {...rest}>
        <Input
            placeholder={placeholder}
            size="large"
            addonBefore={prefix ? <Form.Item
                noStyle
                name={[name, "salutation"]}
                initialValue={prefix === maleNameSelectBefore ? "Mr." : "Mrs."}
            >
                {prefix}
            </Form.Item> : null}
        />
    </Form.Item>
}

const Caricature = (props: { type: string, label: string }) => {
    const {type, label} = props;
    return <Card
        size={"small"}
        hoverable
        title={label}
        style={{width: 130, textAlign: "center"}}
        bodyStyle={{padding: "0"}}
    >
        <Image
            preview={false}
            width={130}
            src={type === "with-caricature" ? "/withCaricature.png" : "/withoutCaricature.png"}
        />
    </Card>
}

const DetailForm: React.FC = () => {
    const [form] = Form.useForm();
    const occasion = Form.useWatch('occasion', form) || "Wedding";
    const addBGPName = Form.useWatch('addBGPName', form);
    const addGGPName = Form.useWatch('addGGPName', form);

    const onFinish = (values: any) => {
        // Function to format name with title case
        function formatName(name:string) {
            return name ? name.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : '';
        }

        // Function to format text to sentence case
        function formatText(text:string) {
            return text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : '';
        }

        // Create the formatted lines
        const brideName = `*Bride name:* ${formatName(values.brideName.name)}`;
        const groomName = `*Groom name:* ${formatName(values.groomName.name)}`;
        const brideGrandParentsName = addBGPName ? (values.bridesGrandFatherName.name || values.bridesGrandMotherName.name
            ? `*Bride’s Grandparents name:* GD/O: ${values.bridesGrandFatherName.salutation} ${formatName(values.bridesGrandFatherName.name)} & ${values.bridesGrandMotherName.salutation} ${formatName(values.bridesGrandMotherName.name)}`
            : '') : "";
        const groomGrandParentsName = addGGPName ? (values.groomsGrandFatherName.name || values.groomsGrandMotherName.name
            ? `*Groom’s Grandparents name:* GS/O: ${values.groomsGrandFatherName.salutation} ${formatName(values.groomsGrandFatherName.name)} & ${values.groomsGrandMotherName.salutation} ${formatName(values.groomsGrandMotherName.name)}`
            : '') : "";
        const parentsOfBride = values.bridesFatherName.name || values.bridesMotherName.name
            ? `*Bride’s Parents name:* D/O: ${values.bridesFatherName.salutation} ${formatName(values.bridesFatherName.name)} & ${values.bridesMotherName.salutation} ${formatName(values.bridesMotherName.name)}`
            : '';
        const parentsOfGroom = values.groomsFatherName.name || values.groomsMotherName.name
            ? `*Groom’s Parents name:* S/O: ${values.groomsFatherName.salutation} ${formatName(values.groomsFatherName.name)} & ${values.groomsMotherName.salutation} ${formatName(values.groomsMotherName.name)}`
            : '';
        const weddingDate = `*${occasion} Date:* ${dayjs(values.weddingDate).format('Do MMM, YYYY')}`;
        const weddingTime = `*${occasion} Time:* ${dayjs(values.weddingTime).format('hh:mm A')}`;
        const weddingVenue = `*${occasion} Venue:* ${formatText(values.weddingVenue)}`;
        const rsvpDetails = `*RSVP:* ${formatText(values.rsvp)}`;
        const hashtag = `*Hashtag:* ${formatText(values.hashtag)}`;
        const events = `*Events:* ${values.selectedEvents ? values.selectedEvents.map((event:any) => formatText(`
        *${event.eventName}* - ${dayjs(event.eventDate).format('Do MMM, YYYY')} | ${dayjs(event.eventTime).format('hh:mm A')} | Venue - ${event.eventVenue}`)).join(', ') : `Only ${values.occasion}`}`;
        const invitationFrom = `*Invitation video is from:* ${formatText(values.invitationFrom)}`;
        const caricature = `*${values.caricature}*`;

        // Combine all the formatted lines with line breaks
        const formattedLines = [
            brideName,
            groomName,
            brideGrandParentsName,
            groomGrandParentsName,
            parentsOfBride,
            parentsOfGroom,
            weddingDate,
            weddingTime,
            weddingVenue,
            rsvpDetails,
            hashtag,
            events,
            invitationFrom,
            caricature
        ].filter(line => line !== '');

        // Create the final formatted string
        const formattedString = formattedLines.join('\n');

        // Output the formatted string
        // console.log(formattedString);
        window.open(`https://api.whatsapp.com/send?phone=917599592220&text=${encodeURIComponent(formattedString)}`, "_blank")
    };

    return <div>
        <Title level={4}>Enter your details</Title>
        <Text type="secondary">We will share this info on WhatsApp</Text>
        <Divider/>
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark="optional"
            initialValues={{ weddingTime: dayjs('20:00:00', 'HH:mm:ss') }}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="occasion"
                        label="Choose Occasion"
                        rules={[{required: true, message: 'Occasion is required'}]}>
                        <Select
                            placeholder="Select an occasion of the invite"
                            allowClear
                            onChange={(value) => {
                                form.setFieldsValue({occasion: value})
                            }}
                            size="large"
                        >
                            {occasionList.map((occasion) => {
                                return <Option key={occasion} value={occasion}>{occasion}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="caricature" label="With or Without custom caricature"
                               rules={[{required: true, message: 'Required'}]}>
                        <Radio.Group>
                            <Radio value="With Caricature">
                                <Caricature type="with-caricature" label="Custom"/>
                            </Radio>
                            <Radio value="Without Caricature">
                                <Caricature type="without-caricature" label="Dummy"/>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="invitationFrom" label="Invitation is from"
                               rules={[{required: true, message: 'Required'}]}>
                        <Radio.Group>
                            <Radio value="Bride Side">Bride Side</Radio>
                            <Radio value="Groom Side">Groom Side</Radio>
                            <Radio value="Both Side">Both Side</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item name="weddingDate" label={`${occasion} Date`}
                               rules={[{required: true, message: `${occasion} date is required`}]}>
                        <DatePicker format="DD/MMM/YYYY" size="large" disabledDate={(current) => dayjs().isAfter(dayjs(current))}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="weddingTime" label={`${occasion} Time`}
                               rules={[{required: true, message: `${occasion} time is required`}]}>
                        <TimePicker size="large" use12Hours format="h:mm a" minuteStep={30} hourStep={1} showNow={false} defaultValue={dayjs('20:00:00', 'HH:mm:ss')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="weddingVenue" label={`${occasion} Venue`}
                               rules={[{required: true, message: `${occasion} venue is required`}]}>
                        <TextArea size="large" placeholder="Enter the location"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="rsvp" label="RSVP">
                        <TextArea size="large" placeholder="Enter the name of person/family who is inviting"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="hashtag" label="Hashtag">
                        <TextArea size="large" placeholder="Enter hashtag for your wedding" defaultValue="#"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.occasion !== currentValues.occasion}
                >
                    {({getFieldValue}) =>
                        (getFieldValue('occasion') === 'Wedding' || getFieldValue('occasion') === 'Engagement') && (
                            <>
                                <Col span={24}>
                                    <Card
                                        size="small"
                                        title="Bride Side Details"
                                    >
                                        <NameField
                                            name={"brideName"}
                                            label={"Bride Name"}
                                            placeholder={"Enter bride name"}
                                            rules={[{required: true, message: 'Bride name is required'}]}
                                        />
                                        <NameField
                                            name={"bridesFatherName"}
                                            label={"Bride's Father Name"}
                                            placeholder={"Enter bride's father name"}
                                            prefix={maleNameSelectBefore}
                                        />
                                        <NameField
                                            name={"bridesMotherName"}
                                            label={"Bride's Mother Name"}
                                            placeholder={"Enter bride's mother name"}
                                            prefix={femaleNameSelectBefore}
                                        />
                                        <Form.Item name="addBGPName" valuePropName="checked">
                                            <Checkbox>Add Bride's Grandparents name</Checkbox>
                                        </Form.Item>
                                        {addBGPName && <>
                                            <NameField
                                            name={"bridesGrandFatherName"}
                                            label={"Bride's Grand Father Name"}
                                            placeholder={"Enter bride's grand father name"}
                                            prefix={maleNameSelectBefore}
                                        />
                                        <NameField
                                            name={"bridesGrandMotherName"}
                                            label={"Bride's Grand Mother Name"}
                                            placeholder={"Enter bride's grand mother name"}
                                            prefix={femaleNameSelectBefore}
                                        /></>}
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card
                                        size="small"
                                        title="Groom Side Details"
                                    >
                                        <NameField
                                            name={"groomName"}
                                            label={"Groom Name"}
                                            placeholder={"Enter groom name"}
                                            rules={[{required: true, message: 'Groom name is required'}]}
                                        />
                                        <NameField
                                            name={"groomsFatherName"}
                                            label={"Groom's Father Name"}
                                            placeholder={"Enter groom's father name"}
                                            prefix={maleNameSelectBefore}
                                        />
                                        <NameField
                                            name={"groomsMotherName"}
                                            label={"Groom's Mother Name"}
                                            placeholder={"Enter groom's mother name"}
                                            prefix={femaleNameSelectBefore}
                                        />
                                        <Form.Item name="addGGPName" valuePropName="checked">
                                            <Checkbox>Add Groom's Grandparents name</Checkbox>
                                        </Form.Item>
                                        {addGGPName && <>
                                            <NameField
                                            name={"groomsGrandFatherName"}
                                            label={"Groom's Grand Father Name"}
                                            placeholder={"Enter groom's grand father name"}
                                            prefix={maleNameSelectBefore}
                                        />
                                        <NameField
                                            name={"groomsGrandMotherName"}
                                            label={"Groom's Grand Mother Name"}
                                            placeholder={"Enter groom's grand mother name"}
                                            prefix={femaleNameSelectBefore}
                                        />
                                        </>}
                                    </Card>
                                </Col>
                            </>
                        )
                    }
                </Form.Item>
            </Row>
            {occasion === "Wedding" && <Row>
                <Col span={24}>
                    <Form.List name="selectedEvents">
                        {(fields, {add, remove}) => (
                            <div
                                style={{
                                    display: 'flex',
                                    rowGap: 16,
                                    flexDirection: 'column',
                                }}
                            >
                                {fields.map((field) => {
                                    return (
                                        <Card
                                            size="small"
                                            title={`Event ${field.name + 1}`}
                                            key={field.key}
                                            extra={
                                                <CloseOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                />
                                            }
                                        >
                                            <Form.Item label="Event" name={[field.name, 'eventName']}
                                                       rules={[{required: true, message: 'Event name is required'}]}>
                                                <Select size="large" placeholder="Select an Event">
                                                    {eventList.map((event) => {
                                                        return <Option key={event} disabled={event === eventList[0]}
                                                                       value={event}>
                                                            {event}
                                                        </Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item name={[field.name, 'eventDate']} label="Event Date"
                                                               rules={[{required: true, message: 'Required'}]}>
                                                        <DatePicker
                                                            //defaultValue={weddingDate}
                                                            size="large"
                                                            format="DD/MMM/YYYY"
                                                            disabledDate={(current) => dayjs().isAfter(dayjs(current))}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name={[field.name, 'eventTime']} label="Event Time"
                                                               rules={[{required: true, message: 'Required'}]}>
                                                        <TimePicker size="large" use12Hours format="h:mm a" minuteStep={30} hourStep={1} showNow={false}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item label="Event Venue" name={[field.name, 'eventVenue']}>
                                                <TextArea size="large" placeholder="Enter the location of event"/>
                                            </Form.Item>
                                        </Card>
                                    )
                                })}

                                <Button type="dashed" onClick={() => add()} block>
                                    + Add Event
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </Col>
            </Row>}
            <Divider/>
            <Row>
                <Col span={24}>
                    <Form.Item>
                        <Button
                            icon={<WhatsAppOutlined />}
                            size="large"
                            block
                            type="primary"
                            style={{ background: "#2ab200" }}
                            htmlType="submit">
                            Submit on WhatsApp
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            {/*<Row>*/}
            {/*    <Form.Item noStyle shouldUpdate>*/}
            {/*        {() => (*/}
            {/*            <Typography>*/}
            {/*                <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>*/}
            {/*            </Typography>*/}
            {/*        )}*/}
            {/*    </Form.Item>*/}
            {/*</Row>*/}
        </Form>
    </div>
}

export default DetailForm;
