import React from 'react';
import DetailForm from "./components/detailForm/DetailForm";
import {Image, Layout} from "antd";

const {Header, Footer, Content} = Layout;

const App: React.FC = () => (
    <Layout>
      <Header style={{display: 'flex', backgroundColor: "#fddbba", justifyContent: "center"}}>
          <Image
              preview={false}
              width={150}
              src="/logo.png"
          />
      </Header>
      <Layout>
        <Content style={{padding: '0 10px', backgroundColor: "#ffffff"}}>
          <DetailForm/>
        </Content>
      </Layout>
      <Footer style={{textAlign: 'center', backgroundColor:"#0e1b4d", color: "#FFF"}}>© 2023, Nyota.shop</Footer>
    </Layout>
);

export default App;
